import { normalize, schema } from 'normalizr'
import { camelizeKeys } from 'humps'

const queryStringFromObject = (queryObject) => {
  return Object.keys(queryObject).reduce(function(a,k){a.push(k + '=' + encodeURIComponent(queryObject[k]));return a},[]).join('&')
}

// Extracts the next page URL from Github API response.
const getNextPageUrl = response => {
  const link = response.headers.get('link')
  if (!link) {
    return null
  }

  const nextLink = link.split(',').find(s => s.indexOf('rel="next"') > -1)
  if (!nextLink) {
    return null
  }

  return nextLink.trim().split(';')[0].slice(1, -1)
}



const API_ROOT = process.env.NODE_ENV === 'development' ? 'https://embersonapi.everlong.digital/' : 'https://embersonapi.everlong.digital/'




// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.

 
function postRequest(url, data, schema) {

 

  var headers = {
    'Content-Type' : 'application/json'
  }
  
   
  if (localStorage.getItem('token')) { 
    headers = {
      'Content-Type' : 'application/json',
      'Authorization' : 'Bearer ' + localStorage.getItem('token')
    }
  }

 
  return fetch(url, {
    credentials: 'same-origin', // 'include', default: 'omit'
    method: 'POST', // 'GET', 'PUT', 'DELETE', etc.
    body:  data, // Coordinate the body type with 'Content-Type'
    headers: new Headers(
      headers
    ),
  })
  .then((response) => {
      return response.json()
        .then((json) => {
          if (!response.ok) {
            return Promise.reject(json)
          }
          const camelizedJson = camelizeKeys(json)
          const nextPageUrl = getNextPageUrl(response)

          return Object.assign({},
            schema ? normalize(camelizedJson, schema) : camelizedJson,
            // normalize(camelizedJson, schema),
            { nextPageUrl }
          )
        })
    })
}

function getRequest(url, schema) {

  var headers = {
    'Content-Type' : 'application/json'
  }
  
   
  if (localStorage.getItem('token')) { 
    headers = {
      'Content-Type' : 'application/json',
      'Authorization' : 'Bearer ' + localStorage.getItem('token')
    }
  }


  return fetch(url, {
    credentials: 'same-origin', // 'include', default: 'omit'
    method: 'GET', // 'GET', 'PUT', 'DELETE', etc. 
    headers: new Headers(
      headers
    ),
  })
  .then((response) => {
      return response.json()
        .then((json) => {
          if (!response.ok) {
            return Promise.reject(json)
          }
          const camelizedJson = camelizeKeys(json)
          const nextPageUrl = getNextPageUrl(response)
 
          return Object.assign({},
            schema ? normalize(camelizedJson, schema) : camelizedJson,
            // normalize(camelizedJson, schema),
            { nextPageUrl }
          )
        })
    })
}


const callApi = (endpoint, schema, method = 'GET', queryObject = '', body = {}) => {

  const queryString = queryStringFromObject(queryObject);

  const fullUrl = (endpoint.indexOf(API_ROOT) === -1) ? API_ROOT + endpoint : endpoint

   

  if (method === 'POST') {
    return postRequest(fullUrl + '?' + queryString, JSON.stringify(body), schema);
  } else {
    return getRequest(fullUrl + '?' + queryString, schema);
  }
}

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/paularmstrong/normalizr

// API may return results with uppercase letters while the query
// doesn't contain any. For example, "someuser" could result in "SomeUser"
// leading to a frozen UI as it wouldn't find "someuser" in the entities.
// That's why we're forcing lower cases down there.


const userSchema = new schema.Entity('users', {}, {
  idAttribute: user => user.iD
})

const proposalsSchema = new schema.Entity('proposals', {}, {
  idAttribute: proposal => proposal.id
})

const companyUsersSchema = new schema.Entity('companyUsers', {}, {
  idAttribute: companyUser => companyUser.userId
})

const bdLevelsSchema = new schema.Entity('bdLevels', {}, {
  idAttribute: bdLevels => bdLevels.id
})

const clientsSchema = new schema.Entity('clients', {}, {
  idAttribute: clients => clients.id
})

const industriesSchema = new schema.Entity('industries', {}, {
  idAttribute: industries => industries.id
})

const embersonCompaniesSchema = new schema.Entity('embersonCompanies', {}, {
  idAttribute: embersonCompanies => embersonCompanies.companyId
})

 
// Schemas for WordPress API responses.
export const Schemas = {
  USER: userSchema,
  USER_ARRAY: [userSchema],
  PROPOSALS: proposalsSchema,
  PROPOSALS_ARRAY: [proposalsSchema],
  COMPANY_USERS: companyUsersSchema,
  COMPANY_USERS_ARRAY: [companyUsersSchema],
  BD_LEVEL: bdLevelsSchema,
  BD_LEVEL_ARRAY: [bdLevelsSchema],
  CLIENTS: clientsSchema,
  CLIENTS_ARRAY: [clientsSchema],
  INDUSTRIES: industriesSchema,
  INDUSTRIES_ARRAY: [industriesSchema],
  EMBERSON_COMPANIES: embersonCompaniesSchema,
  EMBERSON_COMPANIES_ARRAY: [embersonCompaniesSchema]
}

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API'

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API]
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let { endpoint } = callAPI
  const { schema, types, method, query, body } = callAPI

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState())
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }
  // if (!schema) {
  //   throw new Error('Specify one of the exported Schemas.')
  // }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.')
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data)
    delete finalAction[CALL_API]
    return finalAction
  }

  const [ requestType, successType, failureType ] = types
  next(actionWith({ type: requestType }))

  return callApi(endpoint, schema, method, query, body).then(
    response => next(actionWith({
      response,
      type: successType
    })),
    error => next(actionWith({
      type: failureType,
      error: error.message || 'Something bad happened'
    }))
  )
}




